import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const headerProps = {
  preTitle: "",
  title: "404: Not found",
  subtitle: "",
  color: 'is-dark',
  tabs:[],
  isFullHeight: false,
}

const NotFoundPage = () => (
  <Layout headerProps={headerProps}>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
